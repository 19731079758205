import { useRouter } from "next/router";
import { useState } from "react";
import Cookies from "universal-cookie";
import constants from "../constants";

const LoginPage = ({ error }: { error: string }) => {
  const [password, setPassword] = useState("");
  const router = useRouter();

  return (
    <div className="page">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const cookies = new Cookies();
          cookies.set(constants.cookieName, password, {
            path: "/",
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
          });
          router.push("/");
        }}
      >
        <h1 className="mb2">Login</h1>
        {error && <div className="error mb2">{error}</div>}
        <div className="form-input">
          <label htmlFor="password">Paswoord</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            name="pasword"
            required
            type="password"
          />
        </div>
        <button className="primary-button" type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export async function getServerSideProps(context: {
  query: { [key: string]: string };
}) {
  return { props: { error: context.query?.error || "" } };
}
export default LoginPage;
